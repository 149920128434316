<template>
  <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:8" offset="0 s:1">
      <n-h1>{{ t("issues.title") }}</n-h1>
      <tbl
        :data="model"
        :fieldsToHide="['id', 'rentId']"
        :itemsActions="issueItemAction"
        :filterableFields="['State', 'Evidences']"
      />
    </n-gi>
  </n-grid>
</template>
<script>
import { onMounted, computed } from "vue";
import tbl from "@/components/TableBeta.vue";
import { useStore } from "vuex";
import { FolderDetails as DetailIcon } from "@vicons/carbon";
import { useRouter } from "vue-router";
import { NH1, NGrid, NGi } from "naive-ui";
import { useI18n } from "vue-i18n";

export default {
  components: { tbl, NH1, NGrid, NGi },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const model = computed(() =>
      store.state.issues.list.map((x) => {
        return {
          id: x.id,
          Publication: x.rent.publication.title,
          Subject: x.subject,
          Evidences: x.evidences?.length ? x.evidences?.length : 0,
          Date: x.datetime,
          rentId: x.rentId,
          State: x.state,
        };
      })
    );
    const issueItemAction = (item) => {
      return [
        {
          label: "View Detail",
          key: "view",
          icon: DetailIcon,
          props: {
            onClick: () => {
              router.push({ name: "issue-detail", params: { id: item.id } });
            },
          },
        },
      ];
    };

    onMounted(async () => {
      await store.dispatch("issues/GetIssues");
    });

    return {
      t,
      model,
      issueItemAction,
    };
  },
};
</script>
